<template>
    <div id="page-stranger-detail">
        <vs-button class="mb-4 back-detail" type="border" color="warning" @click="strangerList">Quay lại</vs-button>
        <div class="vx-card p-6">
            <div style="display: flex; flex-direction: row">
                <div class="vx-col" style="flex: 1">
                    <img id="product-detail" style="width: 100%" v-bind:src="imageUrl"/>
                </div>
                <div class="vx-col md:w-1/3 w-full info" style="flex: 2">
                    <div class="name">
                        <label>Tên xâm nhập:</label><span> {{ employeeName }}</span>
                    </div>
                    <div class="camera">
                        <label>Tên camera:</label><span> {{ cameraName }}</span>
                    </div>
                    <div class="camera">
                        <label>Ngày xâm nhập:</label><span> {{ displayCreatedAt }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'

export default {
    components: {},
    data() {
        return {
            imageUrl: "",
            employeeName: "",
            cameraName: "",
            displayCreatedAt: ""
        }
    },
    created() {
        this.imageUrl = this.$route.query.imageUrl;
        this.employeeName = this.$route.query.employeName;
        this.cameraName = this.$route.query.cameraName;
        this.displayCreatedAt = this.$route.query.date;
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteCameraStatus'(val) {
            if (val) {
                this.$store.commit('DELETE_CAMERA_STATUS', false);
            }
        },
    },
    computed: {},
    methods: {
        strangerList() {
            this.$router.push(`/user/manage-stranger`).catch(() => {
            })
        }
    },
    mounted() {

    }
}
</script>

<style>
#page-stranger-detail .info > div {
    margin-bottom: 5px;
}

#page-stranger-detail .info {
    padding-left: 20px;
}

#page-stranger-detail .info label {
    font-weight: bold;
    font-size: 14px;
}

.main-vertical.navbar-sticky .router-content {
    position: relative;
}

.back-detail {
    position: absolute;
    right: 0;
    top: -5px;
}
</style>
